.link {
  text-decoration: none;
  display: flex;
  padding: 0.313rem 1rem 0.313rem 3.25rem;
  transition: 250ms;
  border-radius: 0.5rem;
  &:active,
  &:hover {
    background-color: #f7941e;
    p {
      color: #fff !important;
    }
  }
  p {
    font-weight: bold;
  }
}

.collapse {
  padding: 0.5rem 0;
}
