@import 'src/app/assets/styles/global.scss';

.text_color {
  color: $darkBlueText;
  transition: 0.2s;
}

.text_color:hover {
  color: $hoverDarkBlueText;
}
