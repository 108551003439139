.PanelKitchen {
  &__table {
    table,
    th,
    tr,
    td {
      border: 1px solid $softGray3;
      border-collapse: collapse;
      padding: 0.313rem;
    }
    &--product {
      width: 100%;
    }
    &--quantity {
      text-align: center;
    }
  }
  &__close {
    float: right;
    cursor: pointer;
    &--closeIcon {
      color: $secondaryRed;
      transition: 0.3s;
      &:hover {
        color: $hoverRed;
      }
    }
  }
  &__card {
    overflow-y: auto;
    overflow-x: auto;
  }
  &__container {
    min-width: 700px;
    padding-left: 20px;
    justify-content: center;
    &--gridBackground {
      background-color: #f2eeea;
      padding-right: 15px;
      padding-left: 10px;
      margin-right: 10px;
      border-radius: 8px;
      min-height: 300px;
    }
  }
  &__showIcon {
    float: right;
    cursor: pointer;
  }
  &__check {
    float: right;
    cursor: pointer;
    &--checkIcon {
      color: $greenCheck;
      transition: 0.3s;
      &:hover {
        color: $hoverBtnGreen;
      }
    }
  }
  &__card {
    font-size: 13px;
    background-color: white;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    &__food {
      margin-bottom: 10px;
      &__complements {
        padding-left: 10px;
        &__details {
        }
      }
    }
  }
}
