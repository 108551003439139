.NewPanelKitchen {
  &__tableContainer {
    padding-bottom: 0.625rem;
    &:nth-child(odd) {
      padding-right: 0.313rem;
    }
    &:nth-child(even) {
      padding-left: 0.313rem;
    }
  }
  &__table {
    padding: 0.625rem;
    width: auto;
    height: 200px;
    overflow-y: scroll;
    table,
    th,
    tr,
    td {
      border: 1px solid $softGray3;
      border-collapse: collapse;
      padding: 0;
    }
    &--product {
      width: 85%;
    }
    &--itemContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      &--image {
        width: 10%;
        justify-content: center;
        align-items: center;
      }
      &--line {
        width: 90%;
        justify-content: center;
      }
    }
    &--productLabel {
      display: flex;
      justify-content: space-between;
    }
    &--quantity {
      text-align: center;
      width: 15%;
    }
    &--icon {
    }
  }
  &__tableDetail {
    height: auto;
    overflow-y: auto;
  }
  &__close {
    float: right;
    &--closeIcon {
      font-size: 2.5rem;
      cursor: pointer;
      color: $secondaryRed;
      transition: 0.3s;
      &:hover {
        color: $hoverRed;
      }
    }
  }
  &__card {
    overflow-y: auto;
    overflow-x: auto;
  }
  &__container {
    min-width: 700px;
    padding-left: 20px;
    justify-content: center;
    &--gridBackground {
      background-color: $softGray4;
      padding-right: 15px;
      padding-left: 10px;
      margin-right: 10px;
      border-radius: 8px;
      min-height: 300px;
    }
  }
  &__showIcon {
    float: right;
    cursor: pointer;
  }
  &__check {
    float: right;
    &--checkIcon {
      font-size: 2.5rem;
      cursor: pointer;
      color: $greenCheck;
      transition: 0.3s;
      &:hover {
        color: $hoverBtnGreen;
      }
    }
  }
  &__open {
    float: right;
    &--openIcon {
      font-size: 2.5rem;
      cursor: pointer;
      color: $secondaryBlue;
      transition: 0.3s;
      &:hover {
        color: $primaryBlue;
      }
    }
  }
  &__card {
    width: 300px;
    height: 600px;
    font-size: 13px;
    background-color: $normalWhite;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    margin-right: 1.5rem;
    margin-bottom: 1.5rem;
    text-align: left;
    width: 100%;
    margin-bottom: 0.625rem;
    &__food {
      margin-bottom: 0.625rem;
      &__complements {
        padding-left: 0.625rem;
        &__details {
        }
      }
    }
  }
  &__newOrdersContainer {
    // background-color: $softGray4;
  }
  &__detailOrdersContainer {
    // background-color: $softGray4;
  }
  &__paginateIconsContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $softGray4;
    &--pageDisplay {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }
    &--arrow {
      float: right;
      &--arrowIcon {
        cursor: pointer;
        font-size: 5rem;
        color: $primaryButton;
        transition: 0.3s;
        &:hover {
          color: $primaryButtonHover;
        }
      }
      &--arrowIconDisabled {
        font-size: 5rem;
        color: $softGray;
        transition: 0.3s;
        &:hover {
          color: $softGray;
        }
      }
    }
  }
  &__ordersQueue {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 1.25rem 0 1.25rem 0;
    height: auto;
    &--text {
      display: inline;
      background-color: $midOrange;
      color: $normalWhite;
      padding: 0.313rem 1.25rem 0.313rem 1.25rem;
      border-radius: 8px;
    }
  }
  &__changeInPreparationStatus {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
    background-color: $secondaryBlue;
    color: $normalWhite;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 8px;
    &:hover {
      background-color: $primaryBlue;
    }
  }
  &__changeCancelledStatus {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
    background-color: $secondaryRed;
    color: $normalWhite;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 8px;
    &:hover {
      background-color: $hoverRed;
    }
  }
  &__changeCompleteStatus {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px;
    background-color: $greenCheck;
    color: $normalWhite;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 8px;
    &:hover {
      background-color: $hoverBtnGreen;
    }
  }
  &__muiContainer {
    margin-top: 1.25rem;
    // padding-top: 1.25rem;
    min-width: 700px;
    background-color: $normalWhite;
    max-width: 100%;
  }
  &__categoriesContainer {
    &--labelContainer {
      display: flex;
      align-items: center;
    }
    &--arrowIcon {
      cursor: pointer;
      font-size: 3.125rem;
      // margin-top: 1.25rem;
      color: $primaryButton;
      transition: 0.3s;
      &:hover {
        color: $primaryButtonHover;
      }
    }
  }
}
