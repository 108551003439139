$darkBlueText: #223354;
$hoverDarkBlueText: #223354b3;

$greenText: #44d600;
$borderGreen: #3bb900;
$greenCheck: #44d700;
$greenShadow: #44d60080;
$hoverBtnGreen: #36ab00;
$lightGreen: #ecfbe6;

$primaryOrange: #f49200;
$midOrange: #ffa31a;
$warningColor: #ffaf39;
$lightOrange: #fff6e8;
$tintYellow: #efcb9126;

$primaryBlue: #1a75ff;
$secondaryBlue: #0ab6ff;
$infoBlue: #2a779d;

$primaryRed: #ff1a43;
$primaryButton: #f0532d;
$primaryButtonHover: #f7941e;
$lightRed: #ffe8ec;
$secondaryRed: #f4002c;
$hoverRed: #d72041;
$errorRed: #ff1943;

$normalWhite: #fff;

$normalBlack: #000;

$softGray: #8c95a7;
$softGray2: #626e86;
$softGray3: #f6f8fb;
$softGray4: #f2eeea;
$borderGray: #223354;
$inputGray: #cccedd;
$boldGrayText: #797979;
$hoverBtnGray: #585d7f;
$trendingGrayColor: #ecfbe6;
$softBorderGray: #c4c4c4;
$disabledButton: #9099a9;
$inputHelperText: #666666;

$defaultFontFamily: 'Inter', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI',
  'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';

$boxShadow: 0px 9px 16px rgba(159, 162, 191, 0.18),
  0px 2px 2px rgba(159, 162, 191, 0.32);
