@import 'src/app/assets/styles/global.scss';

.closeNotifications {
  background: none;
  border: none;
  color: $secondaryRed;
  cursor: pointer;
  transition: 0.3s;
}
.closeNotifications:hover {
  color: $hoverRed;
  transition: 0.3s;
}
