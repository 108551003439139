.products {
  &__container {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(6rem, 1fr));
    gap: 0.625rem;
    max-height: 25rem;
    overflow-y: scroll;
  }
  &__error {
    min-height: 25rem;
  }
  &__box {
    padding: 0.625rem 0 0.625rem 0;
    text-align: center;
    border-radius: 8px;
    width: 5rem;
    transition: 0.3s;
    border: 1px solid $boldGrayText;
    &:hover,
    &.active {
      border: 1px solid $primaryButton;
      background-color: $lightOrange;
    }
  }
  &__image {
    width: 3.125rem;
    border-radius: 6px;
    // max-height: 3.125rem;
    max-height: 2rem;
  }
  &__title {
    text-align: center;
    font-weight: 700;
    font-size: 0.625rem;
    color: #223354;
    font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    border-top: 1px solid #797979;
    max-height: 3.125rem;
    overflow: hidden;
  }
  &__selected {
    text-align: center;
    padding: 0 1.25rem 0 1.25rem;
    &--image {
      width: 9.375rem;
      border-radius: 8px;
    }
    &--label {
      &--sub {
        color: $softGray;
        font-size: 0.75rem;
      }
    }
    &--quantity-form {
      display: flex;
      justify-content: center;
      align-items: center;
      &--button {
        cursor: pointer;
        margin: 0 1rem;
        font-size: 2.5rem;
        color: $primaryButton;
        transition: 0.3s;
        &:hover {
          color: $primaryButtonHover;
        }
      }
    }
    &--buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__table {
    padding-top: 0.625rem;
    border-radius: 8px;
    width: 100%;
    min-height: 35rem;
    max-height: 31.25px;
    overflow-y: scroll;
    &--cell {
      font-size: 0.625rem;
      text-align: start;
    }
    &--specialInstructions {
      font-weight: bold;
    }
    &--deleteIcon {
      color: $secondaryRed;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        color: $hoverRed;
      }
    }
  }
  &__tableTotal {
    display: flex;
    justify-content: space-around;
    font-size: 0.75rem;
    &--total {
      font-weight: bold;
    }
  }
}
