@import 'src/app/assets/styles/global.scss';

.primaryButton {
  text-decoration: none !important;
  color: white !important;
  border-radius: 0.4rem !important;
  background-color: $primaryButton !important;
  &:hover {
    background-color: $primaryButtonHover !important;
  }
}
