@import './variables.scss';

//Clases de css comunes en la aplicacióin
.errorMessage {
  color: $errorRed;
  font-family: $defaultFontFamily;
  font-size: 13px;
  font-weight: 600;
  margin: 3px 8px 0;
}

.helperMessage {
  // @extend .errorMessage;
  font-size: 0.75rem;
  color: $inputHelperText;
}

.softGrayText {
  color: $softGray;
}

.noTextDecoration {
  text-decoration: none;
}

.textAlignCenter {
  text-align: center;
}

.bgPrimaryBlue {
  background-color: $primaryBlue;
}

.marginZeroAuto {
  margin: 0 auto;
}

.primaryButton {
  color: white;
  background-color: $primaryButton;
  transition: 0.3s;
  border-radius: 0.375rem;
  &:hover {
    background-color: $primaryButtonHover;
  }
}

.disabledButton {
  color: $normalWhite;
  background-color: $disabledButton;
  // &:hover {
  //   background-color: $disabledButton;
  // }
}

.list_description {
  list-style: none;
  padding: 0;
  text-align: start;
}

.selectedPrimaryButton {
  background-color: $primaryButton;
}

.cardForm {
  padding: 10px;
  font-size: 12px;
  margin-bottom: 1.25rem;
}

.orangeBorder {
  border: 1px solid $primaryButton;
}

.flexAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flexAroundWidth {
  @extend .flexAround;
  width: 25rem;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputImage {
  display: none;
}
.labelInputFile {
  align-items: center;
  text-align: center;
}
.labelInputImage {
  margin: 20px;
  background-color: #e0e0e0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  border-radius: 50%;
  height: 70px;
  transition: all ease 500ms;
  box-shadow: 0px 3px 5px -1px #00000033;
  cursor: pointer;
  &:hover {
    background-color: #c3c2c2;
  }
}

.prevImageInput {
  position: relative;
  top: 10px;
  .prevImageFile {
    max-width: 100%;
    max-height: 300px;
    border-radius: 8px;
  }
  .closeImageButton {
    position: absolute;
    margin-left: -2rem;
    top: 0;
    background: none;
    border: none;
    color: $secondaryRed;
    font-size: 34px;
    z-index: 3;
    cursor: pointer;
    transition: 0.3s;
  }
  .closeImageButton:hover {
    color: $hoverRed;
  }
}

.error_message {
  color: $errorRed;
  font-family: $defaultFontFamily;
  font-size: 13px;
  font-weight: 600;
  margin: 3px 8px 0;
}

.flexStartRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.marginLeft1 {
  margin-left: 0.75rem;
}

// This class is used by Material UI to make the label white by default when
// the input is selected (it won't be visible on white backgrounds,
// which is the most likely case in which TextField components will be used).
// Overwriting it so the label remains visible.
.css-1buyxla-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: $softGray;
}

.error_message {
  color: $errorRed;
  font-family: $defaultFontFamily;
  font-size: 13px;
  font-weight: 600;
  margin: 3px 8px 0;
}

.datatable_intervals {
  background-color: white;
  padding: 20px;
}

.standardColorIcon {
  color: $primaryOrange !important;
}

.closeIcon {
  color: $secondaryRed;
  font-size: 3.75rem;
  transition: 0.3s;
}

.closeIcon:hover {
  color: $hoverRed;
  cursor: pointer;
}

.closeIconFile {
  color: $secondaryRed;
  font-size: 2rem;
  transition: 0.3s;
}

.closeIconFile:hover {
  color: $hoverRed;
  cursor: pointer;
}

.checkboxCustom {
  width: 2.5rem;
  height: 2.5rem;
}

.tachedPrice {
  text-decoration: line-through;
  color: $softBorderGray;
}

.linkButton {
  padding: 0.625rem;
  color: $primaryOrange;
  text-decoration: none;
  font-weight: bold;
  font-family: $defaultFontFamily;
}

.subTitleLabel {
  color: $primaryButton;
  font-weight: bold;
}

.generalBox {
  padding: 0.625rem;
  border-radius: 0.375rem;
  background-color: $normalWhite;
  box-shadow: $boxShadow;
}

@import 'src/app/feature/PanelKitchen/PanelKitchen.scss';
@import 'src/app/feature/Orders/styles/CreateOrder.scss';
@import 'src/app/feature/NewPanelKitchen/NewPanelKitchen.scss';
